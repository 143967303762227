import axios from "axios";

async function generateQuestion(){
  try {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_ENGINE_API}/api/quetion/generate_questions-random`,
      headers: {
        'Content-Type': `aplication/json`,
      },
    })
    return result.data.payload
  } catch (error) {
    console.log(error)
    return null
  }
}

export {generateQuestion}