import React from 'react'

function Finished({numCorrect, handleRestart, handleNewQuestion}) {
  let emot = "🤔"
  let message = ""
  if (numCorrect === 0){
    emot = "😭";
    message = `You're ${numCorrect} for 5. Why?`
  }else if (numCorrect < 2){
    emot = "😔";
    message = `You're ${numCorrect} for 5. Is that the best you can do?`
  }else if(numCorrect < 5){
    emot = "🤔"
    message = `You got ${numCorrect} right out of 5! Almost there!`
  }else if (numCorrect === 5){
    emot = "😁"
    message = `Perfect Score! You got 5/5 right!`
  }

  return (
    <div className="flex w-screen m-20 justify-center">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body text-center">
        <h2 className="text-xl mb-5">{message}</h2>
        <p className='text-6xl mb-5'>{emot}</p>
        <button className="btn btn-primary btn-round" onClick={handleRestart}>Retake Practice?</button>
        <button className="btn btn-primary btn-round" onClick={handleNewQuestion}>Retake With New Question</button>
        </div>
      </div>
    </div>
  )
}

export default Finished