import React from 'react'

function NavBar() {
  return (
    <div className="navbar bg-base-100">
      <div className='navbar-start'></div>
      <div className="navbar-center">
        <a href="/" className="text-2xl font-bold">comprehend-ai</a>
      </div>
      <div className='navbar-end'></div>
    </div>
  )
}

export default NavBar