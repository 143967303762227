import React from 'react'

function Hero() {
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">comprehend-ai</h1>
          <p className="py-6">Unlock the power of AI and elevate your reading comprehension with Comprehend-AI! Dive into engaging passages and conquer challenging questions crafted by cutting-edge artificial intelligence. Let your curiosity soar and your skills thrive with every click. </p>
          <a href='/demo' className="btn btn-primary">Try it!</a>
        </div>
      </div>
    </div>
  )
}

export default Hero