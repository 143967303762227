import React, { useEffect } from 'react'
import { useState } from 'react'
import NavBar from '../components/landing/NavBar'
import Question from '../components/main/Question'
import LoadingWithTyping from '../components/utils/LoadingWithTyping'
import { generateQuestion } from '../helper/questionGenerationHelper'
import Finished from '../components/main/Finished'

function Demo() {
	const [view, setView] = useState("generating-article")
  const [questions, setQuestions] = useState()
  const [correctTotal, setCorrectTotal] = useState(0)
	
	let mainView;

  function handleGenerateQuestion(){
    setCorrectTotal(0)
    setView("generating-article")
    generateQuestion().then(
      (result) => {
        setQuestions(result);
        setView("answer-question")
      }
    )
  };

  function handleRestart(){
    setCorrectTotal(0)
    setView("answer-question")
  };

  function handleDone(){
    setView("done")
  }

  function handleCorrectAnswer(){
    setCorrectTotal(correctTotal + 1)
  }

	useEffect(() => {
    handleGenerateQuestion()
  }, []);

	if (view === "generating-article"){
    mainView = (
      <LoadingWithTyping textSequence={[
        "Parsing article from web page...",
        "Generating multiple choice questions...",
        "You will be given up to 5 multiple choice question, depends on the article length.",
        "Good luck!! 😉"
      ]}/>
    )
		
	}else if (view === "answer-question"){
		mainView = (<Question questions={questions} handleDone={handleDone} handleCorrectAnswer={handleCorrectAnswer}/>)
	}else if (view === "done"){
    mainView = (<Finished numCorrect={correctTotal} handleRestart={handleRestart} handleNewQuestion={handleGenerateQuestion}/>)
  }


  return (
    <div className='h-full'>
			<div className='width-screen'>
				<NavBar/>
			</div>
			<div className='w-auto flex justify-center items-center'>
				{mainView}
			</div>
		</div>
  )
}

export default Demo