import React, { useState } from 'react'
import QuestionItem from './QuestionItem'
import Iframe from 'react-iframe'

function Question({questions, handleDone, handleCorrectAnswer}) {
  const [questionIdx, setQuestionIdx] = useState(0)

  function handleNextQuestion(){
    if (questionIdx < 4){
      setQuestionIdx(questionIdx + 1);
    }else{
      handleDone();
    }
  }
  
  return (
    <div className="grid grid-cols-3 gap-4 w-full mx-10">
      <div className="col-span-2">
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
          <h2 className="card-title mb-5">Article</h2>
          <Iframe
            url={questions.source}
            position="inherit"
            width="100%"
            height='500px'
          />
          <p className='text-sm mt-2'>Source: <a className='underline' href={questions.source}>{questions.source}</a></p>
          </div>
        </div>
      </div>
      <div className="">
        <QuestionItem 
          number={questionIdx}
          question={questions.question[questionIdx].question}
          options={questions.question[questionIdx].answer}
          correctIdx={questions.question[questionIdx].correct_idx}
          handleNextQuestion={handleNextQuestion}
          handleCorrectAnswer={handleCorrectAnswer}
        />
      </div>
    </div>
  )
}

export default Question