import React from 'react'
import { TypeAnimation } from 'react-type-animation'

function LoadingWithTyping({textSequence}) {
  let sequence = []
  textSequence.forEach(element => {
    sequence.push(element)
    sequence.push(4000)
  });
  return (
    <div className='flex flex-col justify-center items-center pt-24'>
			<span className="loading text-primary loading-dots loading-lg"></span>
			<TypeAnimation
          sequence={sequence}
          wrapper="span"
          speed={50}
          repeat={0}
          className='normal-case text-lg text-gray-800'
        />
		</div>
  )
}

export default LoadingWithTyping