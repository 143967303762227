import Swal from 'sweetalert2';


function QuestionItem({number, question, options, correctIdx, handleNextQuestion, handleCorrectAnswer}) {
  // const [selectedIdx, setSelectedIdx] = useState(null)
  // const [isAnswered, setIsAnswered] = useState(false)
  let view;

  // useEffect(() => {
  //   setIsAnswered(false)
  // }, [])

  let buttons = []
  options.forEach((option, index) => {
    if (index === correctIdx){
      buttons.push(
        <button
          className="rounded-md shadow-md text-left text-sm bg-yellow-400 hover:bg-yellow-300 p-2"
          key={index}
          onClick={() => {
            // setSelectedIdx(index);
            // setIsAnswered(true);
            Swal.fire({
              title: 'Correct!',
              icon: "success",
              timer: 1200,
              showConfirmButton: false
            })
            handleCorrectAnswer();
            handleNextQuestion();
          }}
        >{option}</button>
      )
    }else{
      buttons.push(
        <button
          className="rounded-md shadow-md text-left text-sm bg-yellow-400 hover:bg-yellow-300 p-2"
          key={index}
          onClick={() => {
            // setSelectedIdx(index);
            // setIsAnswered(true);
            Swal.fire({
              title: 'Wrong answer 😔',
              icon: "error",
              timer: 1200,
              showConfirmButton: false
            })
            handleNextQuestion();
          }}
        >{option}</button>
      )
    }
  });
  view = buttons

  // if (!isAnswered){
  //   let buttons = []
  //   options.forEach((option, index) => {
  //     if (index === correctIdx){
  //       buttons.push(
  //         <button
  //           className="rounded-md shadow-md text-left text-sm bg-yellow-400 hover:bg-yellow-300 p-2"
  //           key={index}
  //           onClick={() => {
  //             setSelectedIdx(index);
  //             setIsAnswered(true);
  //             handleCorrectAnswer();
  //             checkAnswer();
  //           }}
  //         >{option}</button>
  //       )
  //     }else{
  //       buttons.push(
  //         <button
  //           className="rounded-md shadow-md text-left text-sm bg-yellow-400 hover:bg-yellow-300 p-2"
  //           key={index}
  //           onClick={() => {
  //             setSelectedIdx(index);
  //             setIsAnswered(true);
  //             checkAnswer();
  //           }}
  //         >{option}</button>
  //       )
  //     }
  //   });
  //   view = buttons
  // }else{
  //   let buttons = []
  //   options.forEach((option, index) => {
  //     if (index === correctIdx && index === selectedIdx){
  //       buttons.push(<button
  //         key={index}
  //         className="rounded-md shadow-md text-left text-sm bg-green-400 p-2"
  //       >{option}</button>)
  //     }else if (index === selectedIdx && index !== correctIdx){
  //       buttons.push(<button
  //         key={index}
  //         className="rounded-md shadow-md text-left text-sm bg-red-400 p-2"
  //       >{option}</button>)
  //     }else if (index === correctIdx){
  //       buttons.push(<button
  //         key={index}
  //         className="rounded-md shadow-md text-left text-sm bg-green-400 p-2"
  //       >{option}</button>)
  //     }else{
  //       buttons.push(<button
  //         key={index}
  //         className="rounded-md shadow-md text-left text-sm bg-gray-200 p-2"
  //       >{option}</button>)
  //     }
  //   });
  //   view = buttons
  // }

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Question #{number + 1}</h2>
        <p>{question}</p>
        {view}
      </div>
    </div>
  )
}

export default QuestionItem