import React from 'react'
// import Banner from '../components/landing/Banner'
import Hero from '../components/landing/Hero'

function Home() {
  return (
    <div>
      <Hero />
      <footer className="footer footer-center p-10 bg-primary text-primary-content">
        <div>
          <p className="font-bold">
            Created by <a className='underline' href='https://fjoeda.netlify.app'>fjoeda</a>
          </p> 
          <p>This web works best on Desktop or Tablet in landscape mode</p>
        </div> 
      </footer>
    </div>
  )
}

export default Home
