import React from 'react'

function NoPage() {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">404</h1>
        <p className="text-lg text-gray-600">
          The page you are looking for doesn't exist.
        </p>
        <a
          href="/"
          className="text-blue-500 hover:text-blue-700 underline"
        >
          Go to homepage
        </a>
      </div>
    </div>
  );
}

export default NoPage